<template>
  <ViewWindow :data="layersList" :size="resolution" :auto-time="true" />
</template>
<script lang="ts" setup>
import ViewWindow from '@/overlay/components/view-window/index.vue';
import { set, cloneDeep } from 'lodash';
import axios from 'axios';
import { mqttServiceStatus } from '@/service/status-mqtt-service';
import { syncTime } from '@/overlay/utils/tools';

const appId = ref('');

const baseURL = import.meta.env.DEV ? '' : (import.meta.env.VITE_BASEURL as '');

const templateData = ref<any>(null);
const resolution = ref('1920*1080');
let timer: any = null;

const init = async () => {
  loadOverlayData();
  mqttServiceStatus.subscribe(`overlay_play-${appId.value}`, () => {
    loadOverlayData();
  });
  timer = setInterval(() => {
    loadOverlayData();
  }, 60000);
};

const layersList = ref<any>([]);
const loadOverlayData = async () => {
  try {
    const { data } = await axios.get(baseURL + '/api/overlay/app/get', {
      params: { appId: appId.value, show: 1, withTemplate: 1 },
    });
    if (data.code === 15102) return;
    if (data.data.resolution) resolution.value = data.data.resolution;
    templateData.value = JSON.parse(data.data.layerTemplate);
    const list: any[] = [];
    data.data.layerList.forEach((layer: any, i: number) => {
      const layerData = cloneDeep(
        templateData.value.find((temp: any) => {
          return temp.id === layer.layerTemplateId;
        }),
      );
      layer.controlParams.forEach((c: any) => {
        c.params.forEach((param: any) => {
          if (param.path.includes('.timeValue')) {
            param.value = syncTime(param.value) ? syncTime(param.value) : param.value;
          }
          set(layerData, param.path, param.value);
        });
      });
      list.push({
        ...layer,
        transform: layerData!.transform,
        elements: layerData!.elements,
        index: 10 + data.data.layerList.length - i,
      });
    });
    handleLayersListData(list);
  } catch (error) {
    console.log(error);
  }
};

const handleLayersListData = (list: any) => {
  layersList.value.forEach((layer: any) => {
    let sameLayer = list.find((item: any) => {
      return item.id === layer.id;
    });
    if (sameLayer) {
      Object.assign(layer, sameLayer);
    } else {
      layer.show = false;
    }
  });
  list.forEach((item: any) => {
    let sameLayer = layersList.value.find((layer: any) => {
      return item.id === layer.id;
    });
    if (!sameLayer) {
      layersList.value.push(item);
    }
  });
};

onMounted(() => {
  appId.value = window.location.search.split('=')[1].split('&')[0];
  init();
});

onBeforeUnmount(() => {
  if (timer) {
    clearInterval(timer);
  }
  mqttServiceStatus.unsubscribe(`overlay_play-${appId.value}`);
});
</script>
